import React, { Component } from 'react';
import {FormGroup, FormControl, Button, ControlLabel, Alert} from 'react-bootstrap';
import xhr from "../components/xhr";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

class Login extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props){
        super(props);
        let BU = "https://server1.onmiz.org/v2";
        if(window.location.hostname === "testadminold.onmiz.net" || window.location.hostname === "localhost" || window.location.hostname === "192.168.1.102"){
            BU = "https://mizapi.testpad.ir/v2";
        }
        this.state = {
            item: {
                code: '+98',
                mobile: '',
                password: '',
                version: '1.0.9',
                BASE_URL: BU
            },
            submitLoading: false
        };
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        let target = e.target;
        let item = this.state.item;
        item[target.name] = target.value;
        if(target.name === "server"){
            item.BASE_URL = target.value;
        }
        this.setState({item});
    }

    handleSubmit(e){
        e.preventDefault();
        this.setState({submitLoading: true});
        let data = this.state.item;
        new xhr({
            parent: this,
            url: 'login',
            data
        }).Post(response => {
            this.setState({submitLoading: false});
            if(response.status){
                if(response.data.item.allowed_urls) {
                    // const {cookies} = this.props;
                    // cookies.set('token', response.token, {
                    //     maxAge: global.config.cookieLifeTime
                    // });
                    localStorage.setItem('token', response.token);
                    global.config.TOKEN = response.token;
                    // cookies.set('allowed', JSON.stringify(response.data.item.allowed_urls), {
                    //     maxAge: global.config.cookieLifeTime
                    // });
                    localStorage.setItem('allowed', JSON.stringify(response.data.item.allowed_urls));
                    // cookies.set('BASE_URL', this.state.item.BASE_URL, {
                    //     maxAge: global.config.cookieLifeTime
                    // });
                    localStorage.setItem('BASE_URL', this.state.item.BASE_URL);
                    // cookies.set('UID', response.data.item.id, {
                    //     maxAge: global.config.cookieLifeTime
                    // });
                    localStorage.setItem('UID', response.data.item.id);
                    window.location = "/";
                }else{
                    this.setState({message: <Alert bsStyle="danger">شما به این بخش دسترسی ندارید</Alert>});
                }
            }else{
                this.setState({message: <Alert bsStyle="danger">{response.note}</Alert>});
            }
        });
    }

    render(){
        const {item, message, submitLoading} = this.state;
        return (
            <div className="login-box">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    {message}
                    {(window.location.hostname === "testadminold.onmiz.net" || window.location.hostname === "localhost" || window.location.hostname === "192.168.1.102") &&
                        <span>
                        <FormGroup>
                            <ControlLabel>سرور</ControlLabel>
                            <FormControl
                                componentClass="select"
                                name="server"
                                value={item.server}
                                onChange={this.handleChange}
                            >
                                <option value="https://mizapi.testpad.ir/v2">تست</option>
                                <option value="https://server1.onmiz.org/v2">بالا</option>
                            </FormControl>
                        </FormGroup>
                        <FormGroup>
                            <FormControl
                                type="text"
                                name="BASE_URL"
                                className="ltr"
                                value={item.BASE_URL}
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                        </span>
                    }
                    <FormGroup>
                        <ControlLabel>نام کاربری</ControlLabel>
                        <FormControl
                            type="text"
                            name="mobile"
                            value={item.mobile}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>رمز عبور</ControlLabel>
                        <FormControl
                            type="password"
                            name="password"
                            value={item.password}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <Button type="submit" bsStyle="primary" disabled={submitLoading}>{submitLoading ? <span className="glyphicon glyphicon-repeat fast-right-spinner"/> : 'ورود'}</Button>
                </form>
            </div>
        );
    }
}

export default withCookies(Login);